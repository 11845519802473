import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import HeadingMain from '../components/HeadingMain'
import StyledLink from '../components/styles/StyledLink'
import Quote from '../components/styles/Quote'
// import GoogleMap from '../components/GoogleMap'
import SEO from '../components/SEO'

import kontaktMap from '../images/map.svg'

const Container = styled.div`
  max-width: ${(p) => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${(p) => p.theme.paddingOnSides};
  padding-right: ${(p) => p.theme.paddingOnSides};
  display: flex;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 40px;
  }
`

const Left = styled.div`
  position: relative;
  width: 50%;
  padding-right: 80px;
  @media (max-width: 1000px) {
    width: 100%;
    /* padding-right: 0; */
    padding-left: ${(p) => p.theme.paddingOnSides};
    padding-right: ${(p) => p.theme.paddingOnSides};
    margin-bottom: 30px;
  }
`

const Right = styled.div`
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`

const Heading = styled.h1`
  font-size: 24px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: ${(p) => p.theme.textColorDark};
  margin-bottom: 24px;
`

const Portrait = styled(GatsbyImage)`
  width: 40vw;
  min-width: 100%;
  box-shadow: 0 12px 38px ${(p) => p.theme.lightGray};
`

const MapContainer = styled.div`
  max-width: ${(p) => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${(p) => p.theme.paddingOnSides};
  padding-right: ${(p) => p.theme.paddingOnSides};
  /* display: flex; */
  margin-bottom: 90px;
  p {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (max-width: 1000px) {
    /* flex-wrap: wrap; */
    padding-left: 0;
    padding-right: 0;
    p {
      padding-left: ${(p) => p.theme.paddingOnSides};
      padding-right: ${(p) => p.theme.paddingOnSides};
    }
  }
`

// const WaterColor = styled.img`
//   position: absolute;
//   width: 140px;
//   opacity: 0.4;
//   z-index: -1;
//   top: -30px;
//   left: -36px;
//   @media (max-width: 1000px) {
//     width: 170px;
//     left: -5px;
//     top: -32px;
//   }
// `

// const IFrameWrapper = styled.div`
//   width: 100%;
//   position: relative;
//   padding-top: 100%;
//   padding-top: 56.25%;
//   @media (max-width: 550px) {
//     padding-top: 100%;
//   }
// `

// const IFrame = styled.iframe`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// `

const MapImg = styled.img`
  width: 100%;
  max-width: 700px;
  box-shadow: 0 12px 38px ${(p) => p.theme.lightGray};
  margin-top: 24px;
  margin-bottom: 24px;
  @media (max-width: 1000px) {
    max-width: 100%;
  }
`

class Kontakt extends Component {
  render() {
    const { portraitImage } = this.props.data
    return (
      <React.Fragment>
        <SEO title="Kontakt" description="Erreichbarkeit auf vielen Wegen - nehmen Sie Kontakt mit uns auf." />
        <HeadingMain mainHeading="Kontakt" subHeading="Erreichbarkeit auf vielen Wegen" />
        <Container>
          <Left>
            <Heading>Weingut Göschl & Töchter</Heading>
            <p>
              Am Kanal 4<br />
              7122 Gols
              <br />
              <br />
              <StyledLink as="a" href="mailto:office@weingut-goeschl.at">
                office@weingut-goeschl.at
              </StyledLink>
              <br />
              Reinhard:{' '}
              <StyledLink as="a" href="tel:+436644530988">
                +43 664 45 30 988
              </StyledLink>
              <br />
              Daniela:{' '}
              <StyledLink as="a" href="tel:+436605996979">
                +43 660 599 69 79
              </StyledLink>
              <br />
              Kathrin:{' '}
              <StyledLink as="a" href="tel:+436767371761">
                +43 676 73 71 761
              </StyledLink>
              <br />
              <br />
              Für Verkostungen und Weingutsbesichtigungen bitten wir um vorherige Terminvereinbarung per Telefon oder
              E-Mail.
              <br />
              <br />
              Interessiert an einer Weindegustation bei Ihnen vor Ort? Anfragen bitte an{' '}
              <StyledLink as="a" href="mailto:office@weingut-goeschl.at">
                office@weingut-goeschl.at
              </StyledLink>
              <br />
              <br />
            </p>
            <Quote>Wir freuen uns auf Ihren Besuch!</Quote>
          </Left>
          <Right>
            <Portrait
              critical={true}
              image={portraitImage.childImageSharp.gatsbyImageData}
              alt="Weingut Göschl - Familie"
            />
          </Right>
        </Container>
        <MapContainer>
          <MapImg src={kontaktMap} alt="Weingut Göschl - Map" />
          <p>
            Für die genaue Wegbeschreibung finden Sie{' '}
            <StyledLink as="a" target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/SoqXqrXqY2NQoCwAA">
              hier
            </StyledLink>{' '}
            unseren Google Eintrag.
          </p>
        </MapContainer>
        {/* <GoogleMap /> */}
      </React.Fragment>
    )
  }
}

export default Kontakt

export const query = graphql`
  {
    portraitImage: file(relativePath: { eq: "photos/weingut-goeschl-familie.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`
